
/* Create a Parallax Effect */
.parallax-podcasts,
.parallax-skills,
.parallax-books,
.parallax-certs,
.parallax-contact,
.parallax-boardgame {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 1.5em 0;
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1024px) {
    .parallax-podcasts,
    .parallax-skills,
    .parallax-books,
    .parallax-certs,
    .parallax-contact,
    .parallax-boardgame {
        background-attachment: scroll;
    }
}


@media only screen and (max-width: 600px) {
    /* Mobile */
    .parallax-skills {
        background-image: url('../images/parallax-skills-mobile.png');
        min-height: 100%;
    }
    .parallax-podcasts {
        background-image: url('../images/will-francis-ZDNyhmgkZlQ-unsplash-mobile.jpg');
        min-height: 400px;
    }
    .parallax-books {
        background-image: url('../images/patrick-tomasso-Oaqk7qqNh_c-unsplash-mobile.jpg');
        min-height: 400px;
    }
    .parallax-certs {
        background-image: url('../images/qalam-eka-maulana-puEF4yWDJ6Q-unsplash-cropped-mobile.jpg');
        min-height: 400px;
    }
    .parallax-contact {
        background-image: url('../images/parallax-contact-mobile.png');
        min-height: 400px;
    }
    .parallax-boardgame {
        background-image: url('../images/aksel-fristrup-w7eaCH6ShR4-unsplash-mobile.jpg');
        min-height: 400px;
    }
}
@media only screen and (min-width: 601px) {
    /* Desktop */
    .parallax-skills {
        background-image: url('../images/parallax-skills.png');
        min-height: 100%;
    }
    .parallax-podcasts {
        background-image: url('../images/will-francis-ZDNyhmgkZlQ-unsplash.jpg');
        min-height: 400px;
    }
    .parallax-books {
        background-image: url('../images/patrick-tomasso-Oaqk7qqNh_c-unsplash.jpg');
        min-height: 400px;
    }
    .parallax-certs {
        background-image: url('../images/qalam-eka-maulana-puEF4yWDJ6Q-unsplash-cropped.jpg');
        min-height: 400px;
    }
    .parallax-contact {
        background-image: url('../images/parallax-contact.png');
        min-height: 400px;
    }
    .parallax-boardgame {
        background-image: url('../images/aksel-fristrup-w7eaCH6ShR4-unsplash.jpg');
        min-height: 400px;
    }
}
.display-middle{
    display: flex;
    text-align: center;

}
.display-middle-text{
    flex: 1;
    background-size: cover;
    display: flex;
    flex-direction: column;
    font-size: calc(7px + 6vmin);
    justify-content: center;
    min-height: calc(50vh);
    position: relative;
    color: #FFFFFF;
}
