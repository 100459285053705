.App-Body {
    min-height: 60vh;
}

.App-Body > div > .body-text {
    margin: 75px auto;
    max-width: 700px;
    padding: 0 25px;
}

.photo-text {
    display: flex;
    text-align: center;
    justify-content: center;
    align-self: center;
    font-size: calc(10px + 3vmin);
    line-height: 1.5em;
    padding: 0 10px;
}

.paragraph {
    margin-block-start: 1em;
    margin-block-end: 1em;
}
.App-Body * ul {
    margin-block-start: 0;
}