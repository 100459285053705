#nav
{
}

#nav > ul {
    padding-left: 13px;
    padding-right: 13px;
}

#nav > ul > li
{
    display: inline-block;
}

#nav > ul > li:last-child
{
    padding-right: 0;
}

#nav > ul > li > a,
#nav > ul > li > span
{
    display: block;
    padding: 10px 15px;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 1em;
    outline: 0;
    color: #FFF;
}

#nav > ul > li > a.current
{
    background: none;
    padding: 8px 13px;
    border-radius: 40px;
    border: 2px solid #FFF;
    color: #FFF;
}

#nav > ul > li > ul
{
    display: none;
}

@media (max-width:433px) {

    #nav > ul {
        max-width: 190px;
        text-align: center;
    }
}