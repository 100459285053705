html {
    box-sizing: border-box;
    overflow-x: hidden;
}
*,
*:before,
*:after {
    box-sizing: inherit
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: -21px 0 0;
}

/* Fonts - start */
body,input,textarea,select
{
    font-family: 'Arimo', sans-serif;
    font-size: 15pt;
    line-height: 1.5em;
    color: #4d4d4d;
}
/* Fonts - end */

a, a:visited{
    color: #4d4d4d;
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}