#copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;

    background-color: #1d1d1d;
    min-height: 4vh;
}

#copyright > .container {
    color: #dddddd !important;
    font-size: 14px;
}