#header {
    align-items: center;
    /*background: url(../images/sky.jpg) no-repeat;*/
    background: url(../../assets-old-site/images/parallax1.jpg) no-repeat center bottom;
    background-size: cover;
    display: flex;
    flex-direction: column;
    font-size: calc(7px + 2vmin);
    justify-content: center;
    /*min-height: 35vh;*/
    min-height: calc(21px + 100vh);
    position: relative;
}

#header > .flex-box {
    display: flex;
}

#logo > h1 > div {
    display: block;
    padding: 10px 15px;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 1em;
    outline: 0;
    color: #FFF;
}